@charset "UTF-8";
@font-face {
  font-family: "NotoSerifJP";
  src: url("../../Fonts/NotoSerifJP-Regular.otf") format("otf");
}
html {
  font-size: 16px;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 1.28vw;
  }
}
@media (min-width: 1250px) {
  html {
    font-size: 16px;
  }
}

picture {
  display: block;
  height: 100%;
}

img {
  height: 100%;
}

* {
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 500;
}

body {
  color: #101010;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.6em;
}

a img {
  opacity: 1;
  transition: opacity 0.4s;
}
a:hover img {
  opacity: 0.8;
}
a.opacity {
  opacity: 1;
  transition: opacity 0.4s;
}
a.opacity:hover {
  opacity: 0.8;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-jc-sb {
  justify-content: space-between;
}

.f-jc-c {
  justify-content: center;
}

.f-jc-fe {
  justify-content: flex-end;
}

.f-ai-c {
  align-items: center;
}

.f-ai-fs {
  align-items: flex-start;
}

.f-ai-fe {
  align-items: flex-end;
}

.f-ac-c {
  align-content: center;
}

.base {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

@media only screen and (min-width: 1200px) {
  .sp02 {
    display: none !important;
  }
}
@media only screen and (min-width: 1160px) {
  .sp04 {
    display: none !important;
  }
}
@media only screen and (min-width: 1100px) {
  .sp03 {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}
@media only screen and (max-width: 1200px) {
  .pc02 {
    display: none !important;
  }
}
@media only screen and (max-width: 1100px) {
  .pc03 {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}
.section-ttl {
  font-family: NotoSerifJP, serif, "Noto Serif JP";
  font-feature-settings: "palt";
  font-size: 2.25rem;
  line-height: 1.3333333333;
  letter-spacing: 0.14em;
}
@media screen and (max-width: 767px) {
  .section-ttl {
    font-size: 1.4375rem;
  }
}

.section-subttl {
  font-size: 0.875rem;
  font-weight: 300;
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .section-subttl {
    font-size: 0.6875rem;
  }
}

.base-text {
  font-size: 0.875rem;
  letter-spacing: 0.06em;
  line-height: 1.5714285714;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .base-text {
    font-size: 0.8125rem;
  }
}

.inner {
  margin: auto;
  padding: 0 25px;
  max-width: calc(71.25rem + 50px);
}
@media screen and (max-width: 767px) {
  .inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}

.arrow {
  cursor: pointer;
  background: #000;
  aspect-ratio: 1;
  height: 2.6875rem;
  border-radius: 100%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .arrow {
    height: 1.875rem;
  }
}
.arrow::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  aspect-ratio: 12/6;
  background-image: url("../../img/common/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s all ease-in-out;
}
@media screen and (max-width: 767px) {
  .arrow::after {
    width: 0.5rem;
  }
}

a:has(.arrow):hover .arrow::after {
  transform: translate(-20%, -50%);
}

.sec__btn:hover .sec__btn__circle::after {
  transform: translate(-20%, -50%);
}
.sec__btn.construction__btn {
  margin: -10px 0 0 0;
}
.sec__btn.btn02 {
  border: 1px solid rgba(51, 51, 51, 0.6);
  padding: 28px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sec__btn.btn03 {
  border: 1px solid #fff;
  padding: 28px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sec__btn.pc__btn03 {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}
@media (max-width: 768px) {
  .sec__btn.pc__btn03 {
    padding: 33px 0;
  }
}
.sec__btn__box {
  display: flex;
  align-items: center;
}
.sec__btn__text {
  font-size: 16px;
  letter-spacing: 0.08em;
  color: #333;
}
.sec__btn__text.btn__text02 {
  color: #fff;
}
.sec__btn__text.work__btn {
  color: #fff;
  font-size: 16px;
}
@media (max-width: 768px) {
  .sec__btn__text {
    font-size: 15px;
  }
}
.sec__btn__circle {
  background-color: #d84a2a;
  width: 43px;
  height: 43px;
  border-radius: 999px;
  position: relative;
  margin: 0 0 0 18px;
}
.sec__btn__circle.btn__circle02 {
  background-color: #fff;
}
.sec__btn__circle.btn__circle02::after {
  filter: brightness(0);
}
.sec__btn__circle.btn__circle03 {
  background-color: #000;
}
.sec__btn__circle.btn__circle03::after {
  filter: brightness(1);
}
@media (max-width: 768px) {
  .sec__btn__circle {
    width: 30px;
    height: 30px;
    margin: 0 0 0 10px;
  }
}
.sec__btn__circle::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  aspect-ratio: 12/6;
  background-image: url("../../img/common/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  top: 51%;
  left: 53%;
  transform: translate(-50%, -50%);
  transition: 0.3s all ease-in-out;
}
@media (max-width: 768px) {
  .sec__btn__circle::after {
    width: 0.5rem;
  }
}

.lower {
  background-color: #fff;
  padding: 20px 0;
  justify-content: flex-end;
  display: flex;
}
.lower__page {
  margin: 0 25px 0 0;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #101010;
  position: relative;
}
@media (max-width: 768px) {
  .lower__page {
    font-size: 11px;
  }
}
.lower__page::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 10px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  top: 15%;
  left: 93%;
}
@media (max-width: 768px) {
  .lower__page::after {
    width: 5px;
    height: 5px;
    top: 25%;
  }
}
.lower__now {
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.06em;
}
@media (max-width: 768px) {
  .lower__now {
    font-size: 11px;
  }
}

.header__bar__bg {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  border-bottom: 1px solid #fff;
  height: 30px;
  width: 100%;
  white-space: nowrap;
}
@media screen and (max-width: 999px) {
  .header__bar__bg {
    height: 25px;
  }
}

.header__bar {
  font-size: 10px;
  color: #fff;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 999px) {
  .header__bar {
    transform: scale(0.7);
  }
}

body.scroll .header-l__bottom-item-teb {
  top: 5.625rem;
}
@media screen and (max-width: 999px) {
  body.scroll .drawer {
    top: 0rem;
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 105;
  background: #FFFFFF;
}
.header::after {
  position: absolute;
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(51, 51, 51, 0.6);
  z-index: 1;
}
.header__inner {
  padding-left: 1.875rem;
}
@media screen and (max-width: 999px) {
  .header__inner {
    padding-left: 0.625rem;
  }
}
.header__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
@media screen and (max-width: 999px) {
  .header__container {
    align-items: center;
    gap: 1.25rem;
  }
}
.header__logo {
  margin-bottom: 1.5625rem;
}
@media screen and (max-width: 999px) {
  .header__logo {
    margin-bottom: unset;
  }
}
.header__logo a {
  display: block;
  width: 14.375rem;
}
@media screen and (max-width: 999px) {
  .header__logo a {
    width: 8.75rem;
    padding-bottom: unset;
  }
}
.header__logo img {
  display: block;
  object-fit: contain;
}
.header__menus {
  display: flex;
  align-items: center;
}

.header-l {
  padding: 0.625rem 1.5625rem 0 0;
}
@media screen and (max-width: 999px) {
  .header-l {
    display: none;
  }
}
.header-l__top-items {
  display: flex;
  gap: 0.9375rem;
  margin-left: auto;
  width: fit-content;
}
.header-l__top-item a {
  display: block;
  position: relative;
  font-size: 0.75rem;
  font-weight: 700;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: #101010;
  letter-spacing: 0.02em;
  line-height: 1;
  padding: 0.625rem 0.625rem 0.625rem 0.9375rem;
  transition: 0.3s all ease-in-out;
}
.header-l__top-item a:hover {
  color: #C95437;
}
.header-l__top-item a::after {
  content: "";
  display: block;
  position: absolute;
  top: 47%;
  right: 0;
  transform: translateY(-50%) rotate(45deg);
  height: 0.375rem;
  width: 0.375rem;
  border-top: 1px solid #B3B3B3;
  border-right: 1px solid #B3B3B3;
}
.header-l__bottom-items {
  display: flex;
}
.header-l__bottom-item-teb {
  position: fixed;
  top: 7.5rem;
  left: 0;
  visibility: hidden;
  clip-path: inset(0% 0% 100% 0%);
  transition: all 0.5s ease-in-out;
}
.header-l__bottom-item:hover .header-l__bottom-item-teb {
  visibility: visible;
  clip-path: inset(0% 0% 0% 0%);
}
.header-l__bottom-item:hover .tab-menu__inner {
  opacity: 1;
}
.header-l__bottom-item:last-of-type a {
  padding-right: 0;
}
.header-l__bottom-item > a, .header-l__bottom-item > p {
  display: block;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  padding: 0.625rem clamp(0.375rem, 0.98vw, 0.9375rem) 1.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  color: #101010;
  transition: 0.3s all ease-in-out;
}
@media only screen and (max-width: 1300px) and (min-width: 1000px) {
  .header-l__bottom-item > a, .header-l__bottom-item > p {
    font-size: 0.8125rem;
  }
}
.header-l__bottom-item:hover > a, .header-l__bottom-item:hover > p {
  color: #C95437;
}

.header-r {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 999px) {
  .header-r {
    display: flex;
  }
}
.header-r__menus {
  background: #FFFFFF;
  display: flex;
  gap: 1px;
}
.header-r__menu a {
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  display: block;
  aspect-ratio: 1;
  height: 5.625rem;
  padding: 1.25rem 0 0.9375rem;
  color: #FFFFFF;
  background: #101010;
  transition: 0.3s all ease-in-out;
}
@media screen and (max-width: 999px) {
  .header-r__menu a {
    font-feature-settings: unset;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: unset;
  }
}
.header-r__menu a:hover {
  opacity: 0.7;
}
.header-r__menu--contact a {
  background: #C95437;
}
.header-r__menu-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.8125rem;
  height: 1.5625rem;
  align-items: center;
}
@media screen and (max-width: 999px) {
  .header-r__menu-icon {
    height: 1.25rem;
    margin-bottom: unset;
  }
}
.header-r__menu-icon img {
  object-fit: contain;
  max-width: 1.5625rem;
  max-height: 100%;
}
@media screen and (max-width: 999px) {
  .header-r__menu-icon img {
    max-width: 1.4375rem;
  }
}
.header-r__menu-text {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.06em;
  line-height: 1;
}
@media screen and (max-width: 999px) {
  .header-r__menu-text {
    display: none;
  }
}
.header-r__hamburger {
  aspect-ratio: 1;
  width: 3.125rem;
}

.tab-menu {
  width: 100vw;
  max-height: calc(100vh - 5.625rem);
  overflow: auto;
  overscroll-behavior: none;
  background: #101010;
}
.tab-menu__inner {
  opacity: 0;
  transition: 0.4s all ease-in-out;
  transition-delay: 0.3s;
}
.tab-menu__container {
  padding: 1.5625rem 0;
}
.tab-menu__content {
  margin-bottom: 1.5625rem;
}
.tab-menu__content:last-of-type {
  margin-bottom: unset;
}

.tab-c__ttl {
  font-size: 1.125rem;
  letter-spacing: 0.08em;
  font-family: NotoSerifJP, serif, "Noto Serif JP";
  color: #FFFFFF;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.tab-c__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.625rem;
}
.tab-c__items--half {
  grid-template-columns: 1fr 1fr;
}
.tab-c__item {
  border: 1px solid rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
}
.tab-c__item-img {
  width: 50%;
  aspect-ratio: 186/115;
  overflow: hidden;
}
.tab-c__items--half .tab-c__item-img {
  width: 35%;
}
.tab-c__item-texts {
  width: 50%;
  padding: 0 1.25rem;
}
.tab-c__items--half .tab-c__item-texts {
  width: 65%;
  padding: 0 1.875rem;
}
.tab-c__item-ttl {
  font-size: 0.875rem;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 700;
  color: #FFFFFF;
  margin-bottom: 0.25rem;
  line-height: 1.5714285714;
  font-feature-settings: "palt";
  letter-spacing: 0.03em;
}
.tab-c__item-des {
  color: #FFFFFF;
  font-size: 0.75rem;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 500;
  letter-spacing: 0.03em;
  font-feature-settings: "palt";
  line-height: 1.6666666667;
}

.makers {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.625rem;
}
@media screen and (max-width: 999px) {
  .makers {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.1875rem;
  }
}
.makers__item {
  display: block;
  background: #333333;
  padding-top: 0.1875rem;
}
@media screen and (max-width: 999px) {
  .makers__item {
    padding-top: unset;
  }
}
.makers__mark {
  width: 80%;
  margin: auto;
  aspect-ratio: 133/85;
}
@media screen and (max-width: 999px) {
  .makers__mark {
    width: 100%;
  }
}
.makers__name {
  font-size: 0.75rem;
  font-weight: 700;
  color: #FFFFFF;
  text-align: center;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  margin-bottom: 0.75rem;
}
@media screen and (max-width: 999px) {
  .makers__name {
    font-size: 0.625rem;
    margin-bottom: 0.3125rem;
  }
}

.js-drawer.is-active {
  pointer-events: unset;
  opacity: 1;
  visibility: visible;
  clip-path: inset(0% 0% 0% 0%);
}

.js-drawer {
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  clip-path: inset(0% 0% 0% 30%);
}

.drawer {
  width: 100vw;
  height: 100vh;
  background: #101010;
  position: fixed;
  overflow: auto;
  overscroll-behavior: none;
  z-index: 100;
  top: 25px;
  left: 0;
  right: 0;
  display: none;
}
@media screen and (max-width: 999px) {
  .drawer {
    display: block;
  }
}
.drawer__inner {
  max-width: 37.5rem;
  padding: 0 20px 9.375rem;
  margin: auto;
}
.drawer__container {
  padding: 5.625rem 0 0;
}
.drawer__items {
  margin-bottom: 1.875rem;
}
.drawer__item-ttl {
  padding: 1.0625rem 0.3125rem 1.0625rem 0.5rem;
  color: #FFFFFF;
  width: 100%;
  position: relative;
  font-size: 0.8125rem;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  display: block;
}
.drawer__item:first-of-type .drawer__item-ttl {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}
.drawer__item-ttl > .arrow {
  aspect-ratio: 1;
  position: absolute;
  right: 0.3125rem;
  top: 0.8125rem;
  border: 1px solid rgba(255, 255, 255, 0.6);
  height: 1.375rem;
  transform: rotate(90deg);
  transition: all 0.5s ease-in-out;
  width: 1.375rem;
}
.drawer__item-ttl.is-active > .arrow {
  transform: rotate(90deg) rotateY(180deg);
}
.drawer__subitem > a {
  display: block;
  position: relative;
  color: #FFFFFF;
  font-size: 0.8125rem;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  letter-spacing: 0.02em;
  padding: 0.6875rem 0.9375rem 0.6875rem 0;
  width: fit-content;
}
.drawer__subitem > a::after {
  content: "";
  display: block;
  top: 0.875rem;
  right: 0.3125rem;
  position: absolute;
  height: 0.3125rem;
  aspect-ratio: 1;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  transform: rotate(45deg);
}

.js-accord {
  overflow: hidden;
  border: unset;
  transition: all 0.5s ease-in-out;
  height: 0;
  position: relative;
}
.js-accord::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.3s;
}

.js-accord.is-open::after {
  opacity: 1;
}

.accord__inner {
  padding-left: 1.125rem;
}
.accord__container {
  padding-top: 1.4375rem;
}
.accord__container--store {
  padding-top: unset;
}
.accord__content {
  margin-bottom: 1.5625rem;
}
.accord__content:last-of-type {
  margin-bottom: unset;
}

.accord-c__ttl {
  color: #FFFFFF;
  position: relative;
  font-size: 0.875rem;
  font-family: NotoSerifJP, serif, "Noto Serif JP";
  padding: 0 0.625rem;
  width: fit-content;
}
.accord-c__ttl::before, .accord-c__ttl::after {
  position: absolute;
  content: "";
  display: block;
  aspect-ratio: 1;
  background: #FFFFFF;
  height: 4px;
  top: 0.5625rem;
  border-radius: 100%;
}
.accord-c__ttl::before {
  left: 0;
}
.accord-c__ttl::after {
  right: 0;
}
.accord-c__menu {
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding: 0.8125rem 0.625rem 0.8125rem 0;
}
.accord-c__menu:last-of-type {
  border-bottom: unset;
}
.accord-c__menu-ttl {
  font-size: 0.8125rem;
  margin-bottom: 0.1875rem;
  color: #FFFFFF;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 700;
  position: relative;
}
.accord-c__menu-ttl > .arrow {
  display: block;
  position: absolute;
  top: -0.3125rem;
  right: -0.625rem;
  background: none;
}
.accord-c__menu-ttl > .arrow::after {
  width: 0.625rem;
}
.accord-c__des {
  color: #FFFFFF;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 1.7272727273;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
.accord-c__makers {
  margin: 0.9375rem 0;
}

.accord-c:not(:last-of-type) .accord-c__menu:last-of-type {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.is-open.hamburger .hamburger__line:nth-of-type(1) {
  transform: translate(-50%, 0rem) rotate(-45deg);
}
.is-open.hamburger .hamburger__line:nth-of-type(2) {
  opacity: 0;
}
.is-open.hamburger .hamburger__line:nth-of-type(3) {
  transform: translate(-50%, 0rem) rotate(45deg);
}

.hamburger {
  position: relative;
  cursor: pointer;
  display: none;
}
@media screen and (max-width: 999px) {
  .hamburger {
    display: block;
  }
}
.hamburger__line {
  display: block;
  height: 1px;
  background: #101010;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1.125rem;
}
.hamburger__line:nth-of-type(1) {
  transition: 0.3s all ease-in-out;
  transform: translate(-50%, -0.375rem);
}
.hamburger__line:nth-of-type(2) {
  transition: 0.3s all ease-in-out;
  opacity: 1;
  transform: translate(-50%, 0);
}
.hamburger__line:nth-of-type(3) {
  transition: 0.3s all ease-in-out;
  transform: translate(-50%, 0.375rem);
}

.footer {
  background: #FFFFFF;
}
.footer__container {
  padding: 2.1875rem 0 1.875rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer__container {
    display: block;
  }
}
.footer__left {
  width: 20%;
}
@media screen and (max-width: 767px) {
  .footer__left {
    max-width: calc(15.625rem + 40px);
    width: 100%;
    margin: 0 auto 2.1875rem;
  }
}
.footer__logo {
  margin-bottom: 1.875rem;
  width: 13.125rem;
  display: block;
}
@media screen and (max-width: 767px) {
  .footer__logo {
    width: 12.1875rem;
    margin: 0 auto 1.75rem;
  }
}
.footer__logo img {
  object-fit: contain;
}
.footer__button {
  border-radius: 6.25rem;
  padding: 0.4375rem 0.5rem 0.4375rem 0.9375rem;
  width: fit-content;
  min-width: 13.125rem;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #101010;
}
@media screen and (max-width: 767px) {
  .footer__button {
    width: 100%;
  }
}
.footer__button-text {
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  flex-grow: 1;
  text-align: center;
  color: #FFFFFF;
}
@media screen and (max-width: 767px) {
  .footer__button-text {
    padding-left: 1.5625rem;
    font-size: 0.9375rem;
  }
}
.footer__button-arrow {
  background: #101010;
  border: 1px solid #FFFFFF;
  height: 1.875rem;
}
.footer__button--red {
  background: #C95437;
  border: 1px solid #C95437;
}
.footer__button--black {
  background: #101010;
  border: 1px solid #101010;
}
.footer__button--white {
  background: #FFFFFF;
  border: 1px solid #101010;
}
.footer__button--red .footer__button-text {
  color: #FFFFFF;
}
.footer__button--red .footer__button-arrow {
  background: #C95437;
  border: 1px solid #FFFFFF;
}
.footer__button--white .footer__button-text {
  color: #101010;
}
.footer__button--white .footer__button-arrow {
  background: #FFFFFF;
  border: 1px solid #101010;
}
.footer__button--white .footer__button-arrow::after {
  filter: brightness(0);
}
.footer__menus {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .footer__menus {
    width: 100%;
    max-width: calc(15.625rem + 40px);
    margin: auto;
    flex-wrap: wrap;
  }
}
.footer__menus1 {
  width: 30%;
}
@media screen and (max-width: 767px) {
  .footer__menus1 {
    width: 100%;
  }
}
.footer__menus3 {
  width: 30%;
}
@media screen and (max-width: 767px) {
  .footer__menus3 {
    width: 100%;
  }
}
.footer__menu {
  margin-bottom: 0.9375rem;
  width: fit-content;
  padding: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .footer__menu {
    width: unset;
    margin-bottom: 0.75rem;
  }
}
.footer__menu__link, .footer__menu__text {
  width: 100%;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 700;
  color: #101010;
  transition: 0.3s all ease-in-out;
  display: block;
  font-size: 1rem;
}
@media screen and (max-width: 999px) {
  .footer__menu__link, .footer__menu__text {
    letter-spacing: 0;
  }
}
.footer__menu__link:hover {
  color: #C95437;
}
.footer__menu__list {
  padding: 0.625rem 0 0 0.75rem;
}
.footer__menu__list li {
  margin: 0 0 0.9375rem;
}
@media screen and (max-width: 767px) {
  .footer__menu__list li {
    width: fit-content;
  }
}
.footer__menu__list__link, .footer__menu__list__text {
  position: relative;
  color: #101010;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 0.75rem;
  transition: 0.3s all ease-in-out;
  display: block;
}
@media screen and (max-width: 767px) {
  .footer__menu__list__link, .footer__menu__list__text {
    line-height: 1.3;
  }
}
.footer__menu__list__link:hover {
  color: #C95437;
}
.footer__menu__list__link:hover::before {
  background: #C95437;
}
.footer__menu__list__link::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: -0.75rem;
  width: 0.5rem;
  height: 1px;
  background: #101010;
  transition: 0.3s all ease-in-out;
}
.footer__menu--small a {
  font-weight: 500;
}
.footer__totop {
  transform: rotate(-90deg);
  background: #FFFFFF;
  border: 1px solid #101010;
  height: 2.6875rem;
}
@media screen and (max-width: 767px) {
  .footer__totop {
    height: 1.875rem;
    width: 1.875rem;
  }
}
.footer__totop::after {
  filter: brightness(0);
}

.footer-b {
  background: #101010;
}
@media screen and (max-width: 767px) {
  .footer-b__inner {
    max-width: calc(17.1875rem + 40px);
    margin: auto;
  }
}
.footer-b__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9375rem 0;
}
@media screen and (max-width: 767px) {
  .footer-b__container {
    flex-direction: column-reverse;
    padding: 1.5625rem 0;
    position: relative;
  }
}
.footer-b__copy {
  color: #FFFFFF;
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 0.625rem;
  font-weight: 300;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .footer-b__copy {
    width: 100%;
    text-align: left;
  }
}
.footer-b__menus {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .footer-b__menus {
    display: block;
    width: 100%;
    margin-bottom: 0.625rem;
  }
}
.footer-b__menu {
  color: #FFFFFF;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.06em;
  padding: 0.3125rem 1.25rem;
  border-left: 1px solid #FFFFFF;
  transition: 0.3s all ease-in-out;
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
@media screen and (max-width: 767px) {
  .footer-b__menu {
    display: block;
    width: 100%;
    font-size: 0.6875rem;
    border: unset;
    padding: 0.5rem 0;
    text-align: left;
  }
}
.footer-b__menu:hover {
  color: #C95437;
}
.footer-b__menu:last-of-type {
  border-right: 1px solid #FFFFFF;
}
@media screen and (max-width: 767px) {
  .footer-b__menu:last-of-type {
    border: unset;
  }
}
.footer-b .arrow {
  position: absolute;
  top: 1.5625rem;
  right: 0;
  background: unset;
  border: 1px solid #FFFFFF;
}
.footer-b .arrow::after {
  filter: unset;
}

_::-webkit-full-page-media,
_:future,
:root .works-c {
  display: block;
}

_::-webkit-full-page-media,
_:future,
:root .header__logo img {
  display: block;
  height: 3.125rem;
}

_::-webkit-full-page-media,
_:future,
:root .header__logo {
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 999px) {
  _::-webkit-full-page-media,
  _:future,
  :root .header__logo {
    margin-bottom: unset;
  }
}

_::-webkit-full-page-media,
_:future,
:root .footer__logo {
  margin-bottom: 0.625rem;
  margin-top: -0.9375rem;
}
@media screen and (max-width: 767px) {
  _::-webkit-full-page-media,
  _:future,
  :root .footer__logo {
    margin-top: unset;
    margin-bottom: 0.5rem;
  }
}

.maker {
  background: #101010;
}
.maker__container {
  padding-top: 5.3125rem;
  padding-bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .maker__container {
    padding: 2.1875rem 0;
  }
}
.maker__top {
  margin-bottom: 1.5625rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
@media screen and (max-width: 767px) {
  .maker__top {
    margin-bottom: 1.25rem;
  }
}
.maker__subttl {
  color: #C95437;
}
.maker__ttl {
  font-size: 1.75rem;
  color: #FFFFFF;
}
@media screen and (max-width: 767px) {
  .maker__ttl {
    font-size: 1.125rem;
  }
}
.maker__items {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.625rem;
}
@media screen and (max-width: 767px) {
  .maker__items {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.3125rem;
  }
}
.maker__item {
  background: #333333;
  aspect-ratio: 133/85;
}

.aria {
  font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  background: #101010;
  margin-top: -1px;
}
.aria__container {
  padding-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .aria__container {
    padding-bottom: 2.1875rem;
  }
}
.aria__top {
  margin-bottom: 1.5625rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
@media screen and (max-width: 767px) {
  .aria__top {
    margin-bottom: 1.25rem;
  }
}
.aria__subttl {
  color: #C95437;
}
.aria__ttl {
  font-size: 1.75rem;
  color: #FFFFFF;
}
@media screen and (max-width: 767px) {
  .aria__ttl {
    font-size: 1.125rem;
  }
}
.aria__contents {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr;
}
@media screen and (max-width: 767px) {
  .aria__contents {
    gap: 0.9375rem;
  }
}
.aria__name {
  padding-left: 1.875rem;
  font-size: 1.1875rem;
  color: #FFFFFF;
  letter-spacing: 0.05em;
  margin-bottom: 0.9375rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .aria__name {
    font-size: 0.9375rem;
  }
}
.aria__name a,
.aria__name p {
  color: #FFFFFF;
}
.aria__name::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 1px;
  width: 1.25rem;
  background: #FFFFFF;
}
.aria__list {
  color: #FFFFFF;
  letter-spacing: 0;
}
.aria__list a {
  color: #FFFFFF;
}

.works-wrap .sidebar-wrap {
  width: 23%;
  display: block;
}
.works-wrap .sidebar-wrap .sidebar .box {
  margin: 0 0 40px;
}
.works-wrap .sidebar-wrap .sidebar .box .ttl {
  font-size: 14px;
  color: #C95437;
  letter-spacing: 0.06em;
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 200;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.6);
  line-height: 1em;
}
.works-wrap .sidebar-wrap .sidebar .box ul li a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06em;
  display: block;
  padding: 10px 5px 9px;
  line-height: 1.5em;
  border-bottom: 1px solid rgba(51, 51, 51, 0.6);
  color: #000;
}
@media screen and (max-width: 899px) {
  .works-wrap .sidebar-wrap .sidebar .box ul li a {
    font-size: 12px;
  }
}
@media only screen and (min-width: 900px) {
  .works-wrap .sidebar-wrap.sp {
    display: none !important;
  }
  .works-wrap .sidebar-wrap.pc {
    display: block !important;
  }
}
@media only screen and (max-width: 899px) {
  .works-wrap .sidebar-wrap.pc {
    display: none !important;
  }
  .works-wrap .sidebar-wrap.sp {
    display: block !important;
  }
}
@media screen and (max-width: 899px) {
  .works-wrap .sidebar-wrap {
    width: 100%;
    display: block;
    padding: 40px 15px 80px;
  }
  .works-wrap .sidebar-wrap .sidebar .box {
    margin: 0 0 40px;
  }
  .works-wrap .sidebar-wrap .sidebar .box:last-of-type {
    margin: 0;
  }
  .works-wrap .sidebar-wrap .sidebar .box .ttl {
    font-size: 18px;
    font-size: 1.125rem;
    color: #C95437;
    letter-spacing: 0.06em;
    font-family: Gotham, sans-serif;
    font-weight: 300;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.6);
    line-height: 1em;
  }
  .works-wrap .sidebar-wrap .sidebar .box ul li a {
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 0.08em;
    display: block;
    padding: 10px 5px 9px;
    line-height: 1.6em;
    border-bottom: 1px solid rgba(51, 51, 51, 0.6);
    color: #000;
  }
}

.breadcrumb {
  padding: 15px 0;
  border-top: solid 1px rgba(51, 51, 51, 0.6);
}
.breadcrumb .container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  max-width: 1180px;
  width: 100%;
}
.breadcrumb .container .breadcrumb__list {
  width: fit-content;
  margin: 0 0 0 auto;
}
.breadcrumb .container .breadcrumb__list .breadcrumb__item {
  font-size: 11px;
  font-size: 0.6875rem;
  display: inline;
  color: #101010;
}
.breadcrumb .container .breadcrumb__list .breadcrumb__item a {
  color: #101010;
}
.breadcrumb .container .breadcrumb__list .breadcrumb__item:not(:last-child)::after {
  content: "";
  display: inline-block;
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 10px;
  font-size: 0.625rem;
  width: 6px;
  height: 6px;
  border-top: 1px solid currentColor;
  border-right: 1px solid currentColor;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.detail-wrap .sidebar-wrap {
  width: 23%;
  display: block;
}
.detail-wrap .sidebar-wrap .sidebar .box {
  margin: 0 0 40px;
}
.detail-wrap .sidebar-wrap .sidebar .box .ttl {
  font-size: 14px;
  color: #C95437;
  letter-spacing: 0.06em;
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.6);
  line-height: 1em;
}
.detail-wrap .sidebar-wrap .sidebar .box ul li a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06em;
  display: block;
  padding: 10px 5px 9px;
  line-height: 1.5em;
  border-bottom: 1px solid rgba(51, 51, 51, 0.6);
  color: #000;
}
@media screen and (max-width: 899px) {
  .detail-wrap .sidebar-wrap .sidebar .box ul li a {
    font-size: 12px;
  }
}
@media only screen and (min-width: 900px) {
  .detail-wrap .sidebar-wrap.sp {
    display: none !important;
  }
  .detail-wrap .sidebar-wrap.pc {
    display: block !important;
  }
}
@media only screen and (max-width: 899px) {
  .detail-wrap .sidebar-wrap.pc {
    display: none !important;
  }
  .detail-wrap .sidebar-wrap.sp {
    display: block !important;
  }
}
@media screen and (max-width: 899px) {
  .detail-wrap .sidebar-wrap {
    width: 100%;
    display: block;
    padding: 40px 15px 80px;
  }
  .detail-wrap .sidebar-wrap .sidebar .box {
    margin: 0 0 40px;
  }
  .detail-wrap .sidebar-wrap .sidebar .box:last-of-type {
    margin: 0;
  }
  .detail-wrap .sidebar-wrap .sidebar .box .ttl {
    font-size: 18px;
    font-size: 1.125rem;
    color: #C95437;
    letter-spacing: 0.06em;
    font-family: Gotham, sans-serif;
    font-weight: 300;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.6);
    line-height: 1em;
  }
  .detail-wrap .sidebar-wrap .sidebar .box ul li a {
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 0.08em;
    display: block;
    padding: 10px 5px 9px;
    line-height: 1.6em;
    border-bottom: 1px solid rgba(51, 51, 51, 0.6);
    color: #000;
  }
}

.sec-404 {
  padding: 100px 0 140px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-404 {
    padding: 80px 0 100px;
  }
}
.sec-404 .box {
  margin: 0 0 40px;
}
@media only screen and (max-width: 767px) {
  .sec-404 .box {
    margin: 0 0 30px;
  }
}