@charset "UTF-8";
@use "sass:math";



$fontGothic: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$second-font-family: NotoSerifJP,serif,"Noto Serif JP";
$fourth-font-family:'Montserrat', "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;

// font weight
$light:300;
$regular:400;
$normal:500;
$semibold:600;
$bold:700;

// 色
$baseColor: #101010;
$white:#FFFFFF;
$black:#000000;
$black2:#333333;
$black3: #101010;
$red:#C95437;
$gray:#B3B3B3;
$gray2:#969696;
// 追加 //
$keyColor1: #101010;
$btnColor: #C95437;
$borderColor333: rgba(51, 51, 51, 0.6);
$lineColor: rgba(33,33,33,0.6);

// インナー幅
$inner: 1250px;
$padding-pc:25px;
$padding-sp:20px;



// remへの計算式
@function rem($pixels) {
	@return math.div($pixels, 16) * 1rem;
}
// リキッドレイアウト対応
@function vw($window_width, $size) {
	@return math.div($size, $window_width) * 100vw;
}
// 単位を外す
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }
  @return $number;
}

//ブレイクポイント
$breakpoints: (
  md: 767,
  tb: 900,
  lg:999,
);

//メディアクエリー
$mediaquerys: (
  md: "screen and (max-width: #{map-get($breakpoints,'md')}px)",
  tb: "screen and (max-width: #{map-get($breakpoints,'tb')}px)",
  lg: "screen and (max-width: #{map-get($breakpoints,'lg')}px)",
);

// @include mq(){}で書くとブレークポイントが反映される（初期値はmd）
@mixin mq($mediaquery: md) {
	@media #{map-get($mediaquerys, $mediaquery)} {
	  @content;
	}
  }