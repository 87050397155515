@charset "UTF-8";
@use "../utility/variable" as *;

// フォント読み込み
@font-face {
  font-family: "NotoSerifJP";
  src: url("../../Fonts/NotoSerifJP-Regular.otf") format("otf");
}

html {
  //~375px
  @media (max-width: 375px) {
    font-size: vw(375, 16);
  }

  //375px~787px
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: vw(strip-unit($inner), 16);
  }

  //inner~max-screen
  @media (min-width: $inner) {
    font-size: 16px;
  }
}

picture {
  display: block;
  height: 100%;
}

img {
  height: 100%;
}

* {
  font-family: $fontGothic;
  font-weight: $normal;
}

body {
  color: $baseColor;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.6em;
}

a {
  img {
    opacity: 1;
    transition: opacity 0.4s;
  }

  &:hover {
    img {
      opacity: 0.8;
    }
  }

  &.opacity {
    opacity: 1;
    transition: opacity 0.4s;

    &:hover {
      opacity: 0.8;
    }
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-jc-sb {
  justify-content: space-between;
}

.f-jc-c {
  justify-content: center;
}

.f-jc-fe {
  justify-content: flex-end;
}

.f-ai-c {
  align-items: center;
}

.f-ai-fs {
  align-items: flex-start;
}

.f-ai-fe {
  align-items: flex-end;
}

.f-ac-c {
  align-content: center;
}

.base {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

@media only screen and (min-width: 1200px) {
  .sp02 {
    display: none !important;
  }
}

@media only screen and (min-width: 1160px) {
  .sp04 {
    display: none !important;
  }
}

@media only screen and (min-width: 1100px) {
  .sp03 {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

@media only screen and (max-width: 1200px) {
  .pc02 {
    display: none !important;
  }
}

@media only screen and (max-width: 1100px) {
  .pc03 {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

// 共通パーツ↓======================

.section-ttl {
  font-family: $second-font-family;
  font-feature-settings: "palt";
  font-size: rem(36);
  line-height: calc(48 / 36);
  letter-spacing: 0.14em;
  @include mq {
    font-size: rem(23);
  }
}
.section-subttl {
  font-size: rem(14);
  font-weight: $light;
  font-family: $fourth-font-family;
  letter-spacing: 0.08em;
  @include mq {
    font-size: rem(11);
  }
}
.base-text {
  font-size: rem(14);
  letter-spacing: 0.06em;
  line-height: calc(22 / 14);
  font-weight: $normal;
  @include mq {
    font-size: rem(13);
  }
}

.inner {
  margin: auto;
  padding: 0 $padding-pc;
  max-width: calc(rem(1140) + ($padding-pc * 2));
  @include mq {
    max-width: rem(600);
    padding: 0 $padding-sp;
  }
}

.arrow {
  cursor: pointer;
  background: #000;
  aspect-ratio: 1;
  height: rem(43);
  border-radius: 100%;
  position: relative;
  @include mq {
    height: rem(30);
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: rem(12);
    aspect-ratio: 12/6;
    background-image: url("../../img/common/arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s all ease-in-out;

    @include mq {
      width: rem(8);
    }
  }
}

a:has(.arrow):hover {
  .arrow::after {
    transform: translate(-20%, -50%);
  }
}

.sec__btn {
  &:hover {
    .sec__btn__circle {
      &::after {
        transform: translate(-20%, -50%);
      }
    }
  }

  &.construction__btn {
    margin: -10px 0 0 0;
  }

  &.btn02 {
    border: 1px solid rgba(51, 51, 51, 0.6);
    padding: 28px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn03 {
    border: 1px solid #fff;
    padding: 28px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.pc__btn03 {
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;

    @media (max-width: 768px) {
      padding: 33px 0;
    }
  }

  &__box {
    display: flex;
    align-items: center;
  }

  &__text {
    font-size: 16px;
    letter-spacing: 0.08em;
    color: #333;

    &.btn__text02 {
      color: #fff;
    }

    &.work__btn {
      color: #fff;
      font-size: 16px;
    }

    @media (max-width: 768px) {
      font-size: 15px;
    }
  }

  &__circle {
    background-color: #d84a2a;
    width: 43px;
    height: 43px;
    border-radius: 999px;
    position: relative;
    margin: 0 0 0 18px;

    &.btn__circle02 {
      background-color: #fff;

      &::after {
        filter: brightness(0);
      }
    }

    &.btn__circle03 {
      background-color: #000;

      &::after {
        filter: brightness(1);
      }
    }

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
      margin: 0 0 0 10px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: rem(12);
      aspect-ratio: 12/6;
      background-image: url("../../img/common/arrow.svg");
      background-size: contain;
      background-repeat: no-repeat;
      top: 51%;
      left: 53%;
      transform: translate(-50%, -50%);
      transition: 0.3s all ease-in-out;

      @media (max-width: 768px) {
        width: rem(8);
      }
    }
  }
}

.sec__lower {
  .inner {
  }
}

.lower {
  background-color: #fff;
  padding: 20px 0;
  justify-content: flex-end;
  display: flex;

  &__page {
    margin: 0 25px 0 0;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.06em;
    color: #101010;
    position: relative;

    @media (max-width: 768px) {
      font-size: 11px;
    }

    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: 0 10px;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
      top: 15%;
      left: 93%;

      @media (max-width: 768px) {
        width: 5px;
        height: 5px;
        top: 25%;
      }
    }
  }

  &__now {
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.06em;

    @media (max-width: 768px) {
      font-size: 11px;
    }
  }
}

// header
.header__bar__bg {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: $red;
  background: #000;
  border-bottom: 1px solid #fff;
  height: 30px;
  width: 100%;
  white-space: nowrap;
  @include mq(lg) {
    height: 25px;
  }
}
.header__bar {
  font-size: 10px;
  color: #fff;
  font-family: $fontGothic;
  font-weight: bold;
  // padding: 0 10px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // background: $red;
  // min-height: 30px;
  // width: 100%;
  @include mq(lg) {
    transform: scale(0.7);
    // white-space: nowrap;
    // padding: 5px 0;
  }
}
body.scroll {
  .header-l__bottom-item-teb {
    top: rem(90);
    // @include mq(lg) {
    //   top: rem(0);
    // }
  }
  .drawer {
    @include mq(lg) {
      top: rem(0);
    }
  }
}
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 105;
  background: $white;
  &::after {
    position: absolute;
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: $black2, $alpha: 0.6);
    z-index: 1;
  }
  &__inner {
    padding-left: rem(30);
    @include mq(lg) {
      padding-left: rem(10);
    }
  }
  &__container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include mq(lg) {
      align-items: center;
      gap: rem(20);
    }
  }
  &__logo {
    margin-bottom: rem(25);
    @include mq(lg) {
      margin-bottom: unset;
    }
  }
  &__logo a {
    display: block;
    width: rem(230);
    @include mq(lg) {
      width: rem(140);
      padding-bottom: unset;
    }
  }
  &__logo img {
    display: block;
    object-fit: contain;
  }
  &__menus {
    display: flex;
    align-items: center;
  }
}
.header-l {
  padding: rem(10) rem(25) 0 0;
  @include mq(lg) {
    display: none;
  }

  &__top-items {
    display: flex;
    gap: rem(15);
    margin-left: auto;
    width: fit-content;
  }
  &__top-item a {
    display: block;
    position: relative;
    font-size: rem(12);
    font-weight: $bold;
    font-family: $fontGothic;
    color: $baseColor;
    letter-spacing: 0.02em;
    line-height: 1;
    padding: rem(10) rem(10) rem(10) rem(15);
    transition: 0.3s all ease-in-out;
  }
  &__top-item a:hover {
    color: $red;
  }
  &__top-item a::after {
    content: "";
    display: block;
    position: absolute;
    top: 47%;
    right: 0;
    transform: translateY(-50%) rotate(45deg);
    height: rem(6);
    width: rem(6);
    border-top: 1px solid $gray;
    border-right: 1px solid $gray;
  }
  &__bottom-items {
    display: flex;
  }
  &__bottom-item-teb {
    position: fixed;
    // top: rem(90);
    top: rem(120);
    left: 0;
    visibility: hidden;
    clip-path: inset(0% 0% 100% 0%);
    transition: all 0.5s ease-in-out;
  }
  &__bottom-item:hover .header-l__bottom-item-teb {
    visibility: visible;
    clip-path: inset(0% 0% 0% 0%);
  }
  &__bottom-item:hover .tab-menu__inner {
    opacity: 1;
  }

  // &__bottom-item:hover > .header-l__bottom-item-tab{
  //   visibility: visible;
  //   clip-path: inset(0% 0% 0% 0%);
  // }
  &__bottom-item:last-of-type a {
    padding-right: 0;
  }
  &__bottom-item > a,
  &__bottom-item > p {
    display: block;
    font-family: $fontGothic;
    padding: rem(10) clamp(rem(6), calc(98vw / 100), rem(15)) rem(20);
    font-size: rem(14);
    font-weight: $bold;
    line-height: 1;
    color: $baseColor;
    transition: 0.3s all ease-in-out;
    @media only screen and (max-width: 1300px) and (min-width: 1000px) {
      font-size: rem(13);
    }
  }
  &__bottom-item:hover > a,
  &__bottom-item:hover > p {
    color: $red;
  }
}
.header-r {
  position: relative;
  z-index: 2;
  @include mq(lg) {
    display: flex;
  }
  &__menus {
    background: $white;
    display: flex;
    gap: 1px;
  }
  &__menu a {
    font-family: $fontGothic;
    display: block;
    aspect-ratio: 1;
    height: rem(90);
    padding: rem(20) 0 rem(15);
    color: $white;
    background: $baseColor;
    transition: 0.3s all ease-in-out;

    @include mq(lg) {
      font-feature-settings: unset;
      height: rem(50);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: unset;
    }
  }
  &__menu a:hover {
    opacity: 0.7;
  }
  &__menu--contact a {
    background: $red;
  }
  &__menu-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: rem(13);
    height: rem(25);
    align-items: center;
    @include mq(lg) {
      height: rem(20);
      margin-bottom: unset;
    }
  }
  &__menu-icon img {
    object-fit: contain;
    max-width: rem(25);
    max-height: 100%;
    @include mq(lg) {
      max-width: rem(23);
    }
  }
  &__menu-text {
    text-align: center;
    font-size: rem(12);
    font-weight: $bold;
    letter-spacing: 0.06em;
    line-height: 1;
    @include mq(lg) {
      display: none;
    }
  }
  &__hamburger {
    aspect-ratio: 1;
    width: rem(50);
  }
}

.tab-menu {
  width: 100vw;
  max-height: calc(100vh - rem(90));
  overflow: auto;
  overscroll-behavior: none;
  background: $baseColor;
  &__inner {
    opacity: 0;
    transition: 0.4s all ease-in-out;
    transition-delay: 0.3s;
  }
  &__container {
    padding: rem(25) 0;
  }
  &__content {
    margin-bottom: rem(25);
  }
  &__content:last-of-type {
    margin-bottom: unset;
  }
}
.tab-c {
  &__ttl {
    font-size: rem(18);
    letter-spacing: 0.08em;
    font-family: $second-font-family;
    color: $white;
    margin-top: rem(10);
    margin-bottom: rem(10);
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(10);
  }
  &__items--half {
    grid-template-columns: 1fr 1fr;
  }
  &__item {
    border: 1px solid rgba($color: #fff, $alpha: 0.6);
    display: flex;
    align-items: center;
  }
  &__item-img {
    width: 50%;
    aspect-ratio: 186/115;
    overflow: hidden;
  }
  &__items--half &__item-img {
    width: 35%;
  }
  &__item-texts {
    width: 50%;
    padding: 0 rem(20);
  }
  &__items--half &__item-texts {
    width: 65%;
    padding: 0 rem(30);
  }
  &__item-ttl {
    font-size: rem(14);
    font-family: $fontGothic;
    font-weight: $bold;
    color: $white;
    margin-bottom: rem(4);
    line-height: calc(22 / 14);
    font-feature-settings: "palt";
    letter-spacing: 0.03em;
  }
  &__item-des {
    color: $white;
    font-size: rem(12);
    font-family: $fontGothic;
    font-weight: $normal;
    letter-spacing: 0.03em;
    font-feature-settings: "palt";
    line-height: calc(20 / 12);
  }
}

// /header

// maker
.makers {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: rem(10);
  @include mq(lg) {
    grid-template-columns: repeat(4, 1fr);
    gap: rem(3);
  }
  &__item {
    display: block;
    background: $black2;
    padding-top: rem(3);
    @include mq(lg) {
      padding-top: unset;
    }
  }
  &__mark {
    width: 80%;
    margin: auto;
    aspect-ratio: 133/85;
    @include mq(lg) {
      width: 100%;
    }
  }
  &__name {
    font-size: rem(12);
    font-weight: $bold;
    color: $white;
    text-align: center;
    font-family: $fontGothic;
    margin-bottom: rem(12);
    @include mq(lg) {
      font-size: rem(10);
      margin-bottom: rem(5);
    }
  }
}

// drawer

.js-drawer.is-active {
  pointer-events: unset;
  opacity: 1;
  visibility: visible;
  clip-path: inset(0% 0% 0% 0%);
}
.js-drawer {
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  clip-path: inset(0% 0% 0% 30%);
}
.drawer {
  width: 100vw;
  height: 100vh;
  background: $baseColor;
  position: fixed;
  overflow: auto;
  overscroll-behavior: none;
  z-index: 100;
  // top: 0;
  top: 25px;
  left: 0;
  right: 0;
  display: none;
  @include mq(lg) {
    display: block;
  }
  &__inner {
    max-width: rem(600);
    padding: 0 $padding-sp rem(150);
    margin: auto;
  }
  &__container {
    padding: rem(90) 0 0;
  }
  &__items {
    margin-bottom: rem(30);
  }
  &__item-ttl {
    padding: rem(17) rem(5) rem(17) rem(8);
    color: $white;
    width: 100%;
    position: relative;
    font-size: rem(13);
    font-family: $fontGothic;
    font-weight: $bold;
    line-height: 1.2;
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.6);
    display: block;
  }
  &__item:first-of-type &__item-ttl {
    border-top: 1px solid rgba($color: #fff, $alpha: 0.6);
  }
  &__item-ttl > .arrow {
    aspect-ratio: 1;
    position: absolute;
    right: rem(5);
    top: rem(13);
    border: 1px solid rgba($color: #fff, $alpha: 0.6);
    height: rem(22);
    transform: rotate(90deg);
    transition: all 0.5s ease-in-out;
    width: rem(22);
  }
  &__item-ttl.is-active > .arrow {
    transform: rotate(90deg) rotateY(180deg);
  }
  &__subitem > a {
    display: block;
    position: relative;
    color: $white;
    font-size: rem(13);
    font-family: $fontGothic;
    letter-spacing: 0.02em;
    padding: rem(11) rem(15) rem(11) 0;
    width: fit-content;
  }
  &__subitem > a::after {
    content: "";
    display: block;
    top: rem(14);
    right: rem(5);
    position: absolute;
    height: rem(5);
    aspect-ratio: 1;
    border-top: 1px solid rgba($color: #fff, $alpha: 0.6);
    border-right: 1px solid rgba($color: #fff, $alpha: 0.6);
    transform: rotate(45deg);
  }
}

// /drawer
// accord
.js-accord {
  overflow: hidden;
  border: unset;
  transition: all 0.5s ease-in-out;
  height: 0;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba($color: #fff, $alpha: 0.6);
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transition-delay: 0.3s;
  }
}
.js-accord.is-open {
  &::after {
    opacity: 1;
  }
}
.accord {
  &__inner {
    padding-left: rem(18);
  }
  &__container {
    padding-top: rem(23);
  }
  &__container--store {
    padding-top: unset;
  }
  &__content {
    margin-bottom: rem(25);
  }
  &__content:last-of-type {
    margin-bottom: unset;
  }
}
.accord-c {
  &__ttl {
    color: $white;
    position: relative;
    font-size: rem(14);
    font-family: $second-font-family;
    padding: 0 rem(10);
    width: fit-content;
  }

  &__ttl::before,
  &__ttl::after {
    position: absolute;
    content: "";
    display: block;
    aspect-ratio: 1;
    background: $white;
    height: 4px;
    top: rem(9);
    border-radius: 100%;
  }

  &__ttl::before {
    left: 0;
  }
  &__ttl::after {
    right: 0;
  }
  &__menu {
    display: block;
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.6);
    padding: rem(13) rem(10) rem(13) 0;
  }
  &__menu:last-of-type {
    border-bottom: unset;
  }
  &__menu-ttl {
    font-size: rem(13);
    margin-bottom: rem(3);
    color: $white;
    font-family: $fontGothic;
    font-weight: $bold;
    position: relative;
  }
  &__menu-ttl > .arrow {
    display: block;
    position: absolute;
    top: rem(-5);
    right: rem(-10);
    background: none;
    &::after {
      width: rem(10);
    }
  }

  &__des {
    color: $white;
    font-size: rem(11);
    font-weight: $normal;
    line-height: calc(19 / 11);
    font-family: $fontGothic;
  }
  &__makers {
    margin: rem(15) 0;
  }
}
.accord-c:not(:last-of-type) {
  .accord-c__menu:last-of-type {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.6);
  }
}

// /accord

// hamburger
// js関係
.is-open.hamburger {
  .hamburger__line:nth-of-type(1) {
    transform: translate(-50%, rem(0)) rotate(-45deg);
  }
  .hamburger__line:nth-of-type(2) {
    opacity: 0;
  }
  .hamburger__line:nth-of-type(3) {
    transform: translate(-50%, rem(0)) rotate(45deg);
  }
}
.hamburger {
  position: relative;
  cursor: pointer;
  display: none;
  @include mq(lg) {
    display: block;
  }
  &__line {
    display: block;
    height: 1px;
    background: $baseColor;
    position: absolute;
    left: 50%;
    top: 50%;
    width: rem(18);
  }
  &__line:nth-of-type(1) {
    transition: 0.3s all ease-in-out;
    transform: translate(-50%, rem(-6));
  }
  &__line:nth-of-type(2) {
    transition: 0.3s all ease-in-out;
    opacity: 1;
    transform: translate(-50%, 0);
  }
  &__line:nth-of-type(3) {
    transition: 0.3s all ease-in-out;
    transform: translate(-50%, rem(6));
  }
}

// /hamburger

// footer
.footer {
  background: $white;
  &__container {
    padding: rem(35) 0 rem(30);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include mq {
      display: block;
    }
  }
  &__left {
    width: 20%;
    @include mq {
      max-width: calc(rem(250) + ($padding-sp * 2));
      width: 100%;
      margin: 0 auto rem(35);
    }
  }

  &__logo {
    margin-bottom: rem(30);
    width: rem(210);
    display: block;
    @include mq {
      width: rem(195);
      margin: 0 auto rem(28);
    }
  }
  &__logo img {
    object-fit: contain;
  }
  &__button {
    border-radius: rem(100);
    padding: rem(7) rem(8) rem(7) rem(15);
    width: fit-content;
    min-width: rem(210);
    margin-bottom: rem(10);
    display: flex;
    align-items: center;
    gap: rem(8);
    background: $baseColor;
    @include mq {
      width: 100%;
    }
  }
  &__button-text {
    font-family: $fontGothic;
    flex-grow: 1;
    text-align: center;
    color: $white;
    @include mq {
      padding-left: rem(25);
      font-size: rem(15);
    }
  }
  &__button-arrow {
    background: $baseColor;
    border: 1px solid $white;
    height: rem(30);
  }
  &__button--red {
    background: $red;
    border: 1px solid $red;
  }
  &__button--black {
    background: $baseColor;
    border: 1px solid $baseColor;
  }
  &__button--white {
    background: $white;
    border: 1px solid $baseColor;
  }
  &__button--red &__button-text {
    color: $white;
  }
  &__button--red &__button-arrow {
    background: $red;
    border: 1px solid $white;
  }
  &__button--white &__button-text {
    color: $baseColor;
  }
  &__button--white &__button-arrow {
    background: $white;
    border: 1px solid $baseColor;
  }
  &__button--white &__button-arrow::after {
    filter: brightness(0);
  }
  &__menus {
    // width: 60%;
    width: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    @include mq {
      width: 100%;
      max-width: calc(rem(250) + ($padding-sp * 2));
      margin: auto;
      // gap: rem(10);
      flex-wrap: wrap;
    }
  }
  &__menus1 {
    width: 30%;
    @include mq {
      // width: 50%;
      width: 100%;
    }
  }
  &__menus3 {
    width: 30%;
    @include mq {
      // width: 50%;
      width: 100%;
    }
  }
  &__menu {
    margin-bottom: rem(15);
    width: fit-content;
    padding: rem(5);
    @include mq {
      width: unset;
      margin-bottom: rem(12);
    }
  }
  // &__menu a {
  //   width: 100%;
  //   font-family: $fontGothic;
  //   font-weight: $bold;
  //   padding: rem(5);
  //   color: $baseColor;
  //   transition: 0.3s all ease-in-out;
  //   display: block;
  //   @include mq(lg) {
  //     letter-spacing: 0;
  //   }
  // }
  // &__menu a:hover {
  //   color: $red;
  // }
  &__menu__link,
  &__menu__text {
    width: 100%;
    font-family: $fontGothic;
    font-weight: $bold;
    // padding: rem(5);
    color: $baseColor;
    transition: 0.3s all ease-in-out;
    display: block;
    font-size: rem(16);
    @include mq(lg) {
      letter-spacing: 0;
    }
  }
  &__menu__link:hover {
    color: $red;
  }
  &__menu__list {
    padding: rem(10) 0 0 rem(12);
  }
  &__menu__list li {
    margin: 0 0 rem(15);
    @include mq {
      width: fit-content;
    }
  }
  &__menu__list__link,
  &__menu__list__text {
    position: relative;
    color: $baseColor;
    font-family: $fontGothic;
    font-size: rem(12);
    transition: 0.3s all ease-in-out;
    display: block;
    @include mq {
      line-height: 1.3;
    }
  }
  &__menu__list__link:hover {
    color: $red;
    &::before {
      background: $red;
    }
  }
  &__menu__list__link::before {
    content: "";
    position: absolute;
    // top: 50%;
    top: rem(8);
    left: rem(-12);
    // transform: translateY(-50%);
    width: rem(8);
    height: 1px;
    background: $baseColor;
    transition: 0.3s all ease-in-out;
  }
  &__menu--small a {
    font-weight: $normal;
  }
  &__totop {
    transform: rotate(-90deg);
    background: $white;
    border: 1px solid $baseColor;
    height: rem(43);
    @include mq {
      height: rem(30);
      width: rem(30);
    }
  }
  &__totop::after {
    filter: brightness(0);
  }
}
.footer-b {
  background: $baseColor;
  &__inner {
    @include mq {
      max-width: calc(rem(275) + ($padding-sp * 2));
      margin: auto;
    }
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(15) 0;
    @include mq {
      flex-direction: column-reverse;
      padding: rem(25) 0;
      position: relative;
    }
  }
  &__copy {
    color: $white;
    font-family: $fourth-font-family;
    font-size: rem(10);
    font-weight: $light;
    letter-spacing: 0.08em;
    @include mq {
      width: 100%;
      text-align: left;
    }
  }
  &__menus {
    display: flex;
    align-items: flex-start;
    @include mq {
      display: block;
      width: 100%;
      margin-bottom: rem(10);
    }
  }
  &__menu {
    color: $white;
    font-size: rem(12);
    font-weight: $normal;
    letter-spacing: 0.06em;
    padding: rem(5) rem(20);
    border-left: 1px solid $white;
    transition: 0.3s all ease-in-out;
    font-family: $fontGothic;
    @include mq {
      display: block;
      width: 100%;
      font-size: rem(11);
      border: unset;
      padding: rem(8) 0;
      text-align: left;
    }
  }
  &__menu:hover {
    color: $red;
  }
  &__menu:last-of-type {
    border-right: 1px solid $white;
    @include mq {
      border: unset;
    }
  }
  & .arrow {
    position: absolute;
    top: rem(25);
    right: 0;
    background: unset;
    border: 1px solid $white;
  }
  & .arrow::after {
    filter: unset;
  }
}

// /footer

// 共通パーツ↑======================

// safari用
_::-webkit-full-page-media,
_:future,
:root .works-c {
  display: block;
}
_::-webkit-full-page-media,
_:future,
:root .header__logo img {
  display: block;
  height: rem(50);
}
_::-webkit-full-page-media,
_:future,
:root .header__logo {
  margin-bottom: rem(15);
  @include mq(lg) {
    margin-bottom: unset;
  }
}
_::-webkit-full-page-media,
_:future,
:root .footer__logo {
  margin-bottom: rem(10);
  margin-top: rem(-15);
  @include mq {
    margin-top: unset;
    margin-bottom: rem(8);
  }
}

// maker
.maker {
  background: $baseColor;
  &__container {
    padding-top: rem(85);
    padding-bottom: rem(50);
    @include mq {
      padding: rem(35) 0;
    }
  }
  &__top {
    margin-bottom: rem(25);
    display: flex;
    align-items: center;
    gap: rem(20);
    @include mq {
      margin-bottom: rem(20);
    }
  }
  &__subttl {
    color: $red;
  }
  &__ttl {
    font-size: rem(28);
    color: $white;
    @include mq {
      font-size: rem(18);
    }
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: rem(10);
    @include mq {
      grid-template-columns: repeat(3, 1fr);
      gap: rem(5);
    }
  }
  &__item {
    background: $black2;
    aspect-ratio: 133/85;
  }
}

// /maker

// aria
.aria {
  font-family: $fontGothic;
  background: $baseColor;
  margin-top: -1px;
  &__container {
    padding-bottom: rem(80);
    @include mq {
      padding-bottom: rem(35);
    }
  }
  &__top {
    margin-bottom: rem(25);
    display: flex;
    align-items: center;
    gap: rem(20);
    @include mq {
      margin-bottom: rem(20);
    }
  }
  &__subttl {
    color: $red;
  }
  &__ttl {
    font-size: rem(28);
    color: $white;
    @include mq {
      font-size: rem(18);
    }
  }
  &__contents {
    display: grid;
    gap: rem(20);
    grid-template-columns: 1fr;
    @include mq {
      gap: rem(15);
    }
  }
  &__name {
    padding-left: rem(30);
    font-size: rem(19);
    color: $white;
    letter-spacing: 0.05em;
    margin-bottom: rem(15);
    position: relative;
    @include mq {
      font-size: rem(15);
    }
    a,
    p {
      color: $white;
    }
  }
  &__name::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 1px;
    width: rem(20);
    background: $white;
  }
  &__list {
    color: $white;
    letter-spacing: 0;
    a {
      color: $white;
    }
  }
}
// /aria

// 追加共通パーツここから
.works-wrap {
  // サイドバーここから
  .sidebar-wrap {
    width: 23%;
    display: block;
    .sidebar {
      .box {
        margin: 0 0 40px;
        .ttl {
          font-size: 14px;
          color: $btnColor;
          letter-spacing: 0.06em;
          font-family: $fourth-font-family;
          font-weight: 200;
          padding-bottom: 8px;
          border-bottom: 1px solid $borderColor333;
          line-height: 1em;
        }
        ul li a {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.06em;
          display: block;
          padding: 10px 5px 9px;
          line-height: 1.5em;
          border-bottom: 1px solid $borderColor333;
          color: #000;
          @media screen and (max-width: 899px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  // サイドバーのレスポンシブ表示非表示ここから
  @media only screen and (min-width: 900px) {
    .sidebar-wrap.sp {
      display: none !important;
    }
    .sidebar-wrap.pc {
      display: block !important;
    }
  }

  @media only screen and (max-width: 899px) {
    .sidebar-wrap.pc {
      display: none !important;
    }
    .sidebar-wrap.sp {
      display: block !important;
    }
  }
  // サイドバーのレスポンシブ表示非表示ここまで

  // サイドバー画面幅狭めレイアウトここから
  @media screen and (max-width: 899px) {
    .sidebar-wrap {
      width: 100%;
      display: block;
      padding: 40px 15px 80px;
      .sidebar {
        .box {
          margin: 0 0 40px;
          &:last-of-type {
            margin: 0;
          }
          .ttl {
            font-size: 18px;
            font-size: 1.125rem;
            color: $btnColor;
            letter-spacing: 0.06em;
            font-family: Gotham, sans-serif;
            font-weight: 300;
            padding-bottom: 8px;
            border-bottom: 1px solid $borderColor333;
            line-height: 1em;
          }
          ul li a {
            font-size: 12px;
            font-size: 0.75rem;
            letter-spacing: 0.08em;
            display: block;
            padding: 10px 5px 9px;
            line-height: 1.6em;
            border-bottom: 1px solid $borderColor333;
            color: #000;
          }
        }
      }
    }
  }
  // サイドバー画面幅狭めレイアウトここまで
  // サイドバーここまで
}
// パンくずリストここから
.breadcrumb {
  padding: 15px 0;
  border-top: solid 1px $borderColor333;
  .container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
    max-width: 1180px;
    width: 100%;
    .breadcrumb__list {
      width: fit-content;
      margin: 0 0 0 auto;
      .breadcrumb__item {
        font-size: 11px;
        font-size: 0.6875rem;
        display: inline;
        color: $keyColor1;
        a {
          color: $keyColor1;
        }
      }
      .breadcrumb__item:not(:last-child)::after {
        content: "";
        display: inline-block;
        margin-left: 0.5em;
        margin-right: 0.5em;
        font-size: 10px;
        font-size: 0.625rem;
        width: 6px;
        height: 6px;
        border-top: 1px solid currentColor;
        border-right: 1px solid currentColor;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}
// パンくずリストここまで

.detail-wrap {
  // サイドバーここから
  // サイドバーレイアウトここから
  .sidebar-wrap {
    width: 23%;
    display: block;
    .sidebar {
      .box {
        margin: 0 0 40px;
        .ttl {
          font-size: 14px;
          color: $btnColor;
          letter-spacing: 0.06em;
          font-family: $fourth-font-family;
          font-weight: 300;
          padding-bottom: 8px;
          border-bottom: 1px solid $borderColor333;
          line-height: 1em;
        }
        ul li a {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.06em;
          display: block;
          padding: 10px 5px 9px;
          line-height: 1.5em;
          border-bottom: 1px solid $borderColor333;
          color: #000;
          @media screen and (max-width: 899px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  // サイドバーレイアウトここまで

  // サイドバーのレスポンシブ表示非表示ここから
  @media only screen and (min-width: 900px) {
    .sidebar-wrap.sp {
      display: none !important;
    }
    .sidebar-wrap.pc {
      display: block !important;
    }
  }

  @media only screen and (max-width: 899px) {
    .sidebar-wrap.pc {
      display: none !important;
    }
    .sidebar-wrap.sp {
      display: block !important;
    }
  }
  // サイドバーのレスポンシブ表示非表示ここまで

  // サイドバー画面幅狭めレイアウトここから
  @media screen and (max-width: 899px) {
    .sidebar-wrap {
      width: 100%;
      display: block;
      padding: 40px 15px 80px;
      .sidebar {
        .box {
          margin: 0 0 40px;
          &:last-of-type {
            margin: 0;
          }
          .ttl {
            font-size: 18px;
            font-size: 1.125rem;
            color: $btnColor;
            letter-spacing: 0.06em;
            font-family: Gotham, sans-serif;
            font-weight: 300;
            padding-bottom: 8px;
            border-bottom: 1px solid $borderColor333;
            line-height: 1em;
          }
          ul li a {
            font-size: 12px;
            font-size: 0.75rem;
            letter-spacing: 0.08em;
            display: block;
            padding: 10px 5px 9px;
            line-height: 1.6em;
            border-bottom: 1px solid $borderColor333;
            color: #000;
          }
        }
      }
    }
  }
  // サイドバー画面幅狭めレイアウトここまで
  // サイドバーここまで
}
// 追加共通パーツここまで

// 404ページ
.sec-404 {
  padding: 100px 0 140px;
  text-align: center;
  @media only screen and (max-width: 767px) {
    padding: 80px 0 100px;
}
  .box {
    margin: 0 0 40px;
    @media only screen and (max-width: 767px) {
      margin: 0 0 30px;
    }
  }
  // .section-subttl {
  //   color: #c95437;
  // }
}